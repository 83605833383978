import type {RouteConfig} from 'vue-router'

export const routes: RouteConfig[] = [
  {
    path: '/master/login',
    name: 'menu.master.login_management.title',
    component: () => import('@/pages/Master/LoginUsers/index.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/master/material-family',
    name: 'common.material_family',
    component: () => import('@/pages/Master/MaterialFamily/index.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/master/material-variety/',
    name: 'common.material_variety',
    component: () => import('@/pages/Master/MaterialVariety/index.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/master/grade',
    name: 'common.grade',
    component: () => import('@/pages/Master/Grade/index.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/master/member',
    name: 'menu.master.member.title',
    component: () => import('@/pages/Master/Member/index.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/master/setting',
    name: 'menu.master.setting.title',
    component: () => import('@/pages/Master/Setting/index.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/master/customers',
    name: 'menu.master.setting.title',
    component: () => import('@/pages/Master/Customer/index.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/master/products',
    name: 'processed_product.name',
    component: () => import('@/pages/Product/index.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/master/products/:id',
    name: 'processed_product.product_material',
    component: () => import('@/pages/Product/ProductDetails.vue'),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    path: '/master/products/create',
    name: 'processed_product.product_material_create',
    component: () => import('@/pages/Product/ProductDetails.vue'),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    path: '/master/products/:id/copy',
    name: 'processed_product.product_material_copy',
    component: () => import('@/pages/Product/ProductDetails.vue'),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    path: '/master/box-type',
    name: 'master.box_type.title',
    component: () => import('@/pages/Master/BoxType/index.vue'),
    meta: {
      requiresAuth: true
    }
  }
]
