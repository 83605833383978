import {COLOR_RECIPE} from '@/utils/constants'

const commonParams = {
  backgroundColor: COLOR_RECIPE,
  sortable: false,
  color: '#000000',
  isActive: true,
  disabledRow: true,
  onEnableEditMode: () => false
}
export const columns = [
  {
    name: 'item',
    align: 'center',
    value: 'item',
    ...commonParams
  },
  {
    name: 'variety',
    align: 'center',
    value: 'variety',
    ...commonParams
  },
  {
    name: 'product_area',
    align: 'start',
    value: 'product_area',
    ...commonParams
  },
  {
    name: 'grade',
    align: 'start',
    value: 'grade',
    ...commonParams
  },
  {
    name: 'size',
    align: 'start',
    value: 'size',
    ...commonParams
  },
  {
    name: 'bloom',
    align: 'start',
    value: 'bloom',
    ...commonParams
  },
  {
    name: 'box_type_name',
    value: 'box_type_name',
    align: 'start',
    ...commonParams
  },
  {
    name: 'color',
    value: 'color',
    align: 'start',
    ...commonParams
  },
  {
    name: 'note',
    value: 'note',
    align: 'start',
    ...commonParams
  },
  {
    name: 'stem_bouquet',
    value: 'stem_bouquet',
    ...commonParams
  }
]
