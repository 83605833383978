// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {i18n} from '@/plugins'
import {urlPath} from '@/utils'

export const MENU = [
  {
    title: i18n.t(urlPath.SALE.name) as string,
    icon: 'mdi-credit-card-outline',
    path: urlPath.SALE.path
  },
  {
    title: i18n.t(urlPath.CALCULATE_SUMMARY.name) as string,
    icon: 'mdi-credit-card-outline',
    path: urlPath.CALCULATE_SUMMARY.path
  },
  {
    title: i18n.t(urlPath.STANDING_ORDER.name) as string,
    icon: 'mdi-credit-card-outline',
    path: urlPath.STANDING_ORDER.path
  },
  {
    title: i18n.t(urlPath.REMAINING.name) as string,
    icon: 'mdi-credit-card-outline',
    path: urlPath.REMAINING.path
  },
  {
    title: i18n.t('menu.master.title') as string,
    icon: 'mdi-cogs',
    children: [
      {
        title: i18n.t('processed_product.title') as string,
        path: urlPath.MASTER_PRODUCT.path
      },
      {
        title: i18n.t('material_family.title') as string,
        path: '/master/material-family'
      },
      {
        title: i18n.t('master.box_type.title') as string,
        path: '/master/box-type'
      },
      {
        title: i18n.t('master.grade.title') as string,
        path: '/master/grade'
      }
    ]
  }
]
