<template lang="pug">
div
  j-master-dialog(
    ref='master_dialog',
    :background-color='"#007167"',
    :color-text='"white"',
    :height='"55vh"',
    :hide-fields='hideFields',
    :is-add='isAdd',
    :is-on-mobile='isOnMobile',
    :label='isAdd ? $t("item.add") : $t("item.edit")',
    :label-name='$t("common.name")',
    :loading='loading',
    :master-data='itemData',
    :show='show'
    type='item'
    @on-close='closeDialog'
    @on-create='createItem'
    @on-update='updateItem')
    template(#middle)
      validation-observer(ref='observer' v-slot='{invalid}')
        v-text-field#eng_name(v-model='itemData.eng_name' v-next-focus, :label='$t("common.eng_name")')
        v-autocomplete#supplier(
          v-model='itemData.supplier_id'
          v-next-focus,
          :filter='(item, queryText, itemText) => filterBySearchString(item, queryText)',
          :items='suppliersList',
          :label='$t("material_family.supplier")'
          clearable
          hide-details
          item-text='name'
          item-value='id')
          template(v-slot:no-data)
            span.pl-5.grey--text {{$t('message.no_data')}}
</template>
<script>
  import {defineComponent, toRefs, ref, getCurrentInstance, watch, onMounted} from 'vue'
  import {ValidationProvider, ValidationObserver} from 'vee-validate'
  import JMasterDialog from '../JMasterDialog/index'
  import JConfirmDialog from '../JConfirmDialog/index'
  import {api} from '@/plugins'
  import {endpoints, showError, filterBySearchString} from '@/utils'

  const JItemDialog = defineComponent({
    components: {
      JConfirmDialog,
      JMasterDialog,
      ValidationProvider,
      ValidationObserver
    },
    props: {
      show: {type: Boolean, required: true},
      item: {type: Object, required: false, default: null},
      isAdd: {type: Boolean, required: false, default: true},
      prevInput: {type: String, required: false, default: ''},
      isOnMobile: {type: Boolean, required: false, default: false}
    },
    setup(props, {emit}) {
      const {$toast, $root, $refs} = getCurrentInstance().proxy
      const {item} = toRefs(props)
      const suppliersList = ref([])
      const loading = ref(false)
      const hideFields = ['code', 'full_name', 'keyword']

      const itemData = ref({})
      const isSuccessAll = ref(true)
      const showConfirmUsedDialog = ref(false)

      const closeDialog = () => {
        loading.value = false
        emit('on-close')
        resetValidate()
      }

      const validateElement = async () => {
        const validate = await $refs.observer.validate()
        if (!validate) {
          $toast.error($root.$t('message.check_data_type'))
          return false
        }
        return true
      }

      const resetValidate = async () => {
        const validateName = await $refs.observer
        validateName.reset()
      }

      const createItem = async () => {
        loading.value = true
        if (!(await validateElement())) {
          loading.value = false
          return
        }
        try {
          const {data} = await api.post('/api/material-families', itemData.value)
          if (isSuccessAll.value) $toast.success($root.$t('message.create_successful'))
          emit('on-reload', data)
          closeDialog()
        } catch (e) {
          // if api return duplicate --> show dialog confirm and allow save in db
          showError(e, $toast, $root.$t('message.update_failed'))
        } finally {
          loading.value = false
          await resetValidate()
        }
      }

      const updateItem = async () => {
        loading.value = true
        try {
          const {supplier_name, ...bodySave} = itemData.value
          await api.patch('/api/material-families' + `/${itemData.value.id}`, bodySave)
          if (isSuccessAll.value) $toast.success($root.$t('message.create_successful'))
          emit('on-reload')
          closeDialog()
        } catch (e) {
          // if api return duplicate --> show dialog confirm and allow save in db
          showError(e, $toast, $root.$t('message.update_failed'))
        } finally {
          loading.value = false
        }
      }
      // const confirmUpdate = async () => {
      //   try {
      //     await api.post(`${UPDATE_ENDPOINT}check?id=${itemData.value.id}`)
      //     await updateItem()
      //   } catch (e) {
      //     showConfirmUsedDialog.value = true
      //   }
      // }

      const init = async () => {
        if (props.show) {
          if (props.isAdd) {
            const itemInit = {
              name: props.prevInput,
              eng_name: null,
              supplier_id: null
            }
            itemData.value = JSON.parse(JSON.stringify(itemInit))
          } else {
            itemData.value = JSON.parse(JSON.stringify(item.value))
          }
        }
      }

      const getSuppliers = async () => {
        try {
          const {data} = await api.get(`/api/suppliers`)
          suppliersList.value = data
        } catch (e) {
          showError(e, $toast, $root.$t('message.get_data_failed'))
        }
      }

      onMounted(async () => {
        await getSuppliers()
      })
      watch(
        () => props.show,
        () => {
          init()
        }
      )

      return {
        closeDialog,
        loading,
        itemData,
        updateItem,
        createItem,
        showConfirmUsedDialog,
        suppliersList,
        filterBySearchString,
        hideFields
      }
    }
  })

  export default JItemDialog
</script>

<style lang="sass" scoped>
  .content
    padding: 10px
  .required
    content: '99'
    color: red
  .list-item
    max-height: 550px
    overflow-y: auto
  .v-list-item
    padding: 0 !important
    min-height: 35px !important

  .item
    display: flex
    align-items: center

  .item .code
    display: inline-block
    width: 100px
    text-align: left
    padding-left: 20px
  .item .name
    display: inline-block
    width: calc(100% - 100px)
    text-align: left
    padding-right: 20px
  .icon-list
    font-size: 20px
    display: inline-block
    justify-content: center
    align-items: center


  .color-btn
    background-color: #bdbdbd
</style>
