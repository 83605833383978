<template lang="pug">
div
  j-master-dialog(
    ref='master_dialog',
    :background-color='"#007167"',
    :color-text='"white"',
    :height='"30vh"',
    :hide-fields='hideFields',
    :is-add='isAdd',
    :is-on-mobile='isOnMobile',
    :label='isAdd ? $t("master.box_type.add") : $t("master.box_type.edit")',
    :label-name='$t("master.box_type.name")',
    :loading='loading',
    :master-data='boxTypeData',
    :show='show'
    type='size'
    @on-click-input='focusInput = $event'
    @on-close='closeDialog'
    @on-create='createBoxType'
    @on-typing='onTyping'
    @on-update='updateBoxType')
</template>
<script>
  import {defineComponent, toRefs, ref, getCurrentInstance, watch} from 'vue'
  import JMasterDialog from '../JMasterDialog/index'
  import {api} from '@/plugins'
  import {showError, master} from '@/utils'
  const JBoxTypeDialog = defineComponent({
    components: {
      JMasterDialog
    },
    props: {
      show: {
        type: Boolean,
        required: true
      },
      boxType: {
        type: Object,
        required: false,
        default: null
      },
      isAdd: {
        type: Boolean,
        required: false,
        default: true
      },
      prevInput: {
        type: String,
        required: false,
        default: ''
      },
      isOnMobile: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    setup(props, {emit}) {
      const {$toast, $root, $refs} = getCurrentInstance().proxy
      const {boxType} = toRefs(props)
      const loading = ref(false)
      const focusInput = ref('name') // focus default
      const enterKeydown = ref(0)
      const hideFields = ['code', 'full_name', 'keyword']
      const LIST_STATES = ['name'] // input order
      const boxTypeData = ref({})

      const closeDialog = async () => {
        loading.value = false
        emit('on-close')
        await resetValidate()
      }
      const createBoxType = async () => {
        loading.value = true
        await resetValidate()
        try {
          const {data} = await api.post(`/api/box-types`, boxTypeData.value)
          $toast.success($root.$t('message.create_successful'))
          emit('on-reload', data)
          await closeDialog()
        } catch (e) {
          showError(e, $toast, $root.$t('message.created_failed'))
        } finally {
          loading.value = false
        }
      }

      const updateBoxType = async () => {
        loading.value = true
        try {
          await api.patch(`/api/box-types/${boxTypeData.value.id}`, boxTypeData.value)
          $toast.success($root.$t('message.update_successful'))
          emit('on-reload')
          await closeDialog()
        } catch (e) {
          showError(e, $toast, $root.$t('message.update_failed'))
        } finally {
          loading.value = false
        }
      }
      const onTyping = event => {
        enterKeydown.value = 0
        const oldFocus = focusInput.value
        focusInput.value = master.moveCursor(event, $refs, LIST_STATES, focusInput.value)
        if (
          focusInput.value === LIST_STATES[LIST_STATES.length - 1] &&
          event.keyCode === 13 &&
          oldFocus === focusInput.value
        )
          $refs.master_dialog.$refs.dialog_container.$refs.save_btn.$el.click()
      }

      const onFocus = event => {
        focusInput.value = 'bxt'
        if (event.keyCode === 13) {
          enterKeydown.value += 1
          if (enterKeydown.value === 2) onTyping(event)
        }
      }

      const resetValidate = async () => {
        const validateName = await $refs.observerBxt
        validateName?.reset()
      }
      const init = async () => {
        focusInput.value = 'bxt'
        enterKeydown.value = 0
        await resetValidate()
        if (props.show) {
          if (props.isAdd) {
            const boxTypeInit = {
              name: props.prevInput
            }
            boxTypeData.value = JSON.parse(JSON.stringify(boxTypeInit))
          } else {
            boxTypeData.value = JSON.parse(JSON.stringify(boxType.value))
          }
        }
      }

      watch(
        () => props.show,
        () => {
          init()
        }
      )

      return {
        closeDialog,
        loading,
        boxTypeData,
        updateBoxType,
        createBoxType,
        focusInput,
        onTyping,
        onFocus,
        hideFields
      }
    }
  })

  export default JBoxTypeDialog
</script>
<style scoped lang="sass">
  .add-new
    padding: 0
  .add-new-item
    justify-content: center
</style>
