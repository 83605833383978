<template lang="pug">
v-app.pa-0.ma-0(:class='appClass')
  j-header-menu.center(
    v-if='isLoggedIn',
    :back='true',
    :class='isMobile ? "main--mobile" : ""',
    :is-mobile='isMobile',
    :is-resize='isResize',
    :isNotFixedHeader='hasReturnToTop && isMobile'
    @open-menu='isOpenNav = true')
  v-main
    j-navigation-menu(v-if='isLoggedIn' v-model='isOpenNav', :is-mobile='isMobile' @toggle='toggleNavigation')
    //- JSplash(v-if='computedLoading')
    router-view.router-view(:class='isMobile ? "main--mobile" : ""', :key='$route.path')
  v-btn.scroll-top-btn.mx-2(v-if='showScrollButton' color='white' dark fab large outlined @click='scrollToTop')
    v-icon.custom-icon(color='primary') mdi-arrow-up-bold
</template>

<script>
  import {defineComponent, ref, watch, getCurrentInstance, computed, onMounted, onUnmounted, onBeforeUnmount} from 'vue'
  import {JHeaderMenu, JNavigationMenu} from 'components'
  import {urlPath, checkScreenSize} from '@/utils'
  import {history} from './store/history'
  import {NON_LOGGED_IN_PAGES} from 'utils/constants'

  const App = defineComponent({
    components: {
      JHeaderMenu,
      JNavigationMenu
    },
    setup() {
      const instance = getCurrentInstance()
      const {$root, $store} = instance.proxy
      /**
       * get nonLoggedInPage to hidden header and navigation for pages that they have not logged in.
       *  - case 1: if we first go to page or reload page then $route.name = null
       *  - case 2: we go to non logged in pages
       * @type {ComputedRef<unknown>}
       */
      const isLoggedIn = computed(() => {
        const noAuth = $root.$route.name == null || NON_LOGGED_IN_PAGES.some(page => page.name === $root.$route.name)
        return !noAuth
      })

      const enableBack = ref(false)
      const isOpenNav = ref(false)
      const start = ref(true)
      const screenType = ref(checkScreenSize())
      const isResize = ref(false)
      const isMobile = computed(() => {
        const {path} = $root.$route
        if (path.includes('mobile') || path.includes('tablet')) return true
        switch (screenType.value) {
          case 'mobile':
            return true
          case 'tablet':
            return true
          case 'pc':
            return false
          default:
            return false
        }
      })
      const toggleNavigation = () => {
        isOpenNav.value = !isOpenNav.value
      }

      const computedLoading = computed(() => {
        return $store.state.loadingIndicator
      })
      const appClass = computed(() => {
        const {path} = $root.$route
        if (path.includes('mobile')) return 'main-app'
        switch (screenType.value) {
          case 'mobile':
            return 'main-app'
          case 'pc':
            return 'v-main__wrap'
          default:
            return 'v-main__wrap'
        }
      })
      const showScrollButton = ref(false)

      const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }
      const hasReturnToTop = ref(false)
      const checkScrollPosition = () => {
        showScrollButton.value = window.pageYOffset > 100 && hasReturnToTop.value && isMobile.value
      }

      watch(
        () => $root.$route,
        () => {
          isResize.value = screen.width <= 375
          const {path} = $root.$route
          start.value = path !== '/'
          const isMainUrl = urlPath.DISABLE_NAVIGATION.find(url => url === path)
          enableBack.value = !isMainUrl

          // check return to top button
          const ASSIGNMENT_MOBILE_FORM_REGEX = /^\/assignment\/mobile\/form\/\d+$/
          hasReturnToTop.value = !urlPath.NOT_HAS_RETURN_TO_TOP.find(
            url => url === path || ASSIGNMENT_MOBILE_FORM_REGEX.test(path)
          )

          // check histories
          if (history.state.histories.length > 1) {
            enableBack.value = !isMainUrl
          } else {
            enableBack.value = false
          }
          if (path.includes('mobile')) {
            enableBack.value = false
          }
        }
      )
      const onWidthChange = () => {
        screenType.value = checkScreenSize()
      }
      onMounted(() => {
        window.addEventListener('resize', onWidthChange)
        window.addEventListener('scroll', checkScrollPosition)
      })
      onBeforeUnmount(() => {
        window.removeEventListener('scroll', checkScrollPosition)
      })
      onUnmounted(() => window.removeEventListener('resize', onWidthChange))
      return {
        isLoggedIn,
        isOpenNav,
        toggleNavigation,
        enableBack,
        checkScreenSize,
        isMobile,
        computedLoading,
        appClass,
        isResize,
        showScrollButton,
        scrollToTop,
        hasReturnToTop
      }
    }
  })
  export default App
</script>

<style lang="sass">
  @import '@/style/css/master.sass'
  //This css to prevent double scroll on mobile, tablet
  @media only screen and (min-device-width: 200px) and (max-device-width: 1200px)
  .main-app > .v-application--wrap
    max-height: -webkit-fill-available
    min-height: -webkit-fill-available
  .router-view
    padding-top: 2px
    padding-left: 8px
  @media (max-width: 820px)
    .nav-hide
      padding-left: 2px
  .v-main__wrap
    position: inherit !important
  .center
    margin: auto
  .main--mobile
    max-width: 900px !important
    background-color: white
    height: 100vh
  .scroll-top-btn
    position: fixed
    top: 40px
    right: 16px
    z-index: 999
    padding: 10px
    border: 3px solid var(--v-primary-base) !important
    opacity: 0.6
    border-radius: 50px
    cursor: pointer
  .custom-icon
    font-size: 36px !important
</style>
