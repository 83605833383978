const HOME = {path: '/', name: 'home.title'}
const MATERIAL_VARIETY = {path: '/master/material-variety/', name: 'common.material_variety'}
const SIZE = {path: '/master/size/', name: 'master.size_master.size.title'}
const QUALITY_GROUP = {
  path: '/master/quality_group/',
  name: 'master.quality_master.quality_group.title'
}
const QUALITY = {
  path: '/master/quality/',
  name: 'master.quality_master.quality.title'
}
const COLOR = {path: '/master/color', name: 'common.color'}
const CATEGORY = {path: '/master/category', name: 'common.category'}
const CUSTOMER = {path: '/master/customer/', name: 'master.customer.title'}
const LOGIN_CALLBACK = {path: '/account/callback/', name: 'login.callback'}
const SETTING = {path: '/setting/', name: 'setting.title'}

const SALE = {path: '/sales-amount-input', name: 'sale.title'}
const CALCULATE_SUMMARY = {path: '/summary', name: 'summary.title'}
const MASTER_PRODUCT = {path: '/master/products', name: 'processed_product.product_material'}
const MASTER_PRODUCT_CREATE = {path: '/master/products', name: 'processed_product.product_material_create'}
const MASTER_PRODUCT_COPY = {path: '/master/products', name: 'processed_product.product_material_copy'}
const PROCESSED_PRODUCT = {path: '/master/processed-product', name: 'processed_product.name'}
const LOGIN = {path: '/login/', name: 'validation.login.title'}
const ROLE = {path: '/master/role', name: 'master.role.title'}

const STANDING_ORDER = {path: '/standing-orders', name: 'standing_orders.title'}
const REMAINING = {path: '/remaining-report', name: 'remaining.title'}

const DISABLE_NAVIGATION = [HOME.path]
const NOT_HAS_RETURN_TO_TOP = [HOME.path]

export {
  HOME,
  QUALITY_GROUP,
  QUALITY,
  MATERIAL_VARIETY,
  SIZE,
  COLOR,
  CATEGORY,
  CUSTOMER,
  LOGIN_CALLBACK,
  SETTING,
  SALE,
  LOGIN,
  ROLE,
  PROCESSED_PRODUCT,
  CALCULATE_SUMMARY,
  MASTER_PRODUCT,
  MASTER_PRODUCT_CREATE,
  MASTER_PRODUCT_COPY,
  STANDING_ORDER,
  DISABLE_NAVIGATION,
  NOT_HAS_RETURN_TO_TOP,
  REMAINING
}
