<template lang="pug">
v-menu(
  v-model='isOpenDatePicker',
  :close-on-content-click='false',
  :nudge-bottom='2'
  min-width='200px !important'
  offset-y)
  template(v-slot:activator='{on, attrs}')
    validation-provider(
      v-slot='{errors}',
      :name='label + (uniqueKey ? uniqueKey : "") || $t("common.auction_date")',
      :rules='{required}')
      v-text-field(
        ref='dateInputRef'
        v-next-focus:[group],
        :class='{"input-date-outlined": outlined, "input-date": !outlined}',
        :clearable='clearable',
        :dense='dense',
        :disabled='disable',
        :error-messages='getErrorMessages(errors)',
        :hide-details='hideDetails',
        :key='componentKey',
        :outlined='outlined',
        :placeholder='placeholder',
        :readonly='readonly',
        :style='maxWidth ? `max-width: ${maxWidth}px` : ""',
        :value='dateDisplay'
        append-icon='mdi-calendar'
        color='#007167'
        @blur='handleInputValue'
        @click.native='handleClick'
        @click:append='isOpenDatePicker = true'
        @click:clear='clearDate()'
        @focus='clearTextField($event)'
        @input='onInput'
        @keydown.tab='handleInputValue'
        @keyup.enter='handleInputValue')
        template(v-if='!isStatisticsPage && isShowLabel' v-slot:label)
          span {{label || $t('common.auction_date')}}
          span.red--text(v-if='required') *
          guide-tooltip(v-if='isHaveGuide')
            template(#customContent)
              v-btn.icon-btn(icon tabindex='-1')
                v-icon.icon-label mdi-information-outline
      v-menu(
        v-if='isInputKeyMatched'
        v-model='menu',
        :activator='$refs.dateInputRef',
        :content-class='classForMenu'
        offset-y)
        v-list.tooltip(v-if='isShowGuide')
          div(@click='$event.stopPropagation()')
            .triangle
            p.mb-0 {{$t('date_picker_guideline.guideline_icon')}}
            p.mb-0.second.pb-0 {{$t('date_picker_guideline.guideline_input')}}
              guide-tooltip(:currentMember='currentMember', :haveConfirmBtn='true' @success='hideGuideline')
                template(#customContent)
                  span {{$t('date_picker_guideline.guideline_format')}}
            .pb-3.confirm-container
              v-checkbox.mt-0.check-box(
                v-model='isConfirm',
                :label='$t("date_picker_guideline.not_show_guideline")'
                color='white'
                hide-details)
              .save-container
                v-btn.save(
                  ref='save_btn',
                  :loading='loading'
                  background-color='white'
                  color='white'
                  @click='hideGuideline')
                  | {{$t('common.ok')}}
  v-date-picker.date-picker(
    v-model='dateData',
    :allowed-dates='allowedDates',
    :day-format='formatDateDay',
    :max='maxDate',
    :min='minDate'
    color='primary'
    header-color='primary'
    locale='ja-jp'
    scrollable
    @change='(isOpenDatePicker = false), saveDate()')
    h3.label {{label}}
    v-spacer
    v-btn.white--text(:large='!$vuetify.breakpoint.xsOnly' color='red' dark text @click='isOpenDatePicker = false')
      span {{$t('common.cancel')}}
</template>

<script>
  import moment from 'moment'
  import {defineComponent, ref, computed, getCurrentInstance, watch, nextTick, onMounted, onUnmounted} from 'vue'
  import {pickDate} from '@/utils'
  import GuideTooltip from './GuideTooltip.vue'
  import {api} from '@/plugins'
  import {endpoints, showError} from '@/utils'
  import {isEmpty} from 'lodash'

  const CommonDatePicker = defineComponent({
    components: {GuideTooltip},
    props: {
      date: {
        type: String,
        default: null
      },
      label: {
        type: String,
        default: null
      },
      clearable: {
        type: Boolean,
        default: false
      },
      autofocus: {
        type: Boolean,
        default: false
      },
      isStatisticsPage: {
        type: Boolean,
        default: false
      },
      group: {
        type: String,
        default: 'default'
      },
      allowedDates: {
        type: Function,
        required: false
      },
      outlined: {default: false},
      dense: {default: false},
      hideDetails: {default: true},
      maxWidth: {default: null},
      formatDate: {default: 'time_month_date'},
      formatDay: {
        type: String,
        default: 'DD'
      },
      minDate: {default: ''},
      maxDate: {default: ''},
      disable: {default: false},
      required: {default: false},
      readonly: {default: false},
      isHaveGuide: {default: true},
      pickerKey: {default: 1},
      firstClickDatePickerKey: {default: 1},
      errorMessages: {type: Array, default: null},
      allowNullValue: {type: Boolean, default: false},
      placeholder: {type: String, required: false},
      isShowLabel: {type: Boolean, required: false, default: true},
      uniqueKey: {type: String, required: false}
    },
    setup(props, {emit}) {
      const {$root, $store, $toast, $refs} = getCurrentInstance().proxy
      const isOpenDatePicker = ref(false)
      const isShowGuide = ref(true)
      const currentMember = ref(null)
      const menu = ref(false)
      const classForMenu = ref('menu-guide')
      const loading = ref(false)
      const dateData = ref(props.date)
      const isConfirm = ref(false)
      const dateFormat = ref($root.$t(`common.${props.formatDate}`))
      const dateDisplay = computed(() => {
        const dateMoment = moment(dateData.value)
        return dateMoment._isValid ? dateMoment.format(dateFormat.value) : ''
      })

      const formatDateDay = date => {
        return moment(date).format(props.formatDay)
      }

      const isInputKeyMatched = computed(() => {
        return $store.state.common.firstClickDatePickerKey === props.pickerKey
      })
      const menuActivator = computed(() => {
        return document.querySelector('#menu-activator')
      })
      const saveDate = () => {
        handleInputValue()
        dateInputRef.value.blur()
        emit('save-date', dateData.value)
        dateFormat.value = $root.$t(`common.${props.formatDate}`)
        setTabindex()
      }
      const clearDate = () => {
        emit('save-date', null)
        dateData.value = null
        prevDateData.value = null
        setTabindex()
      }
      const valueInput = ref('')
      const dateInputRef = ref(null)
      const componentKey = ref(0)
      const prevDateData = ref('')
      const handleInputValue = () => {
        if (!valueInput.value && !props.allowNullValue) {
          nextTick(() => {
            dateData.value = prevDateData.value
            if (!prevDateData.value) {
              // componentKey.value += 1
            }
            dateInputRef.value.blur()
            setTabindex()
          })
        } else {
          nextTick(() => {
            // componentKey.value += 1
            dateData.value = valueInput.value
            emit('save-date', isEmpty(valueInput.value) ? null : valueInput.value)
            setTabindex()
            dateInputRef.value.blur()
          })
        }
        dateFormat.value = $root.$t(`common.${props.formatDate}`)
      }
      const onInput = e => {
        e = e ?? ''
        const d = pickDate(e)
        if (d !== null && !d.includes('NaN')) {
          valueInput.value = pickDate(e)
        } else {
          valueInput.value = null
        }
      }
      const handleBlur = () => {
        if (props.date == null) {
          prevDateData.value = null
        }
        nextTick(() => {
          if (!prevDateData.value) {
            componentKey.value += 1
          } else {
            dateData.value = prevDateData.value
            dateFormat.value = $root.$t(`common.${props.formatDate}`)
          }
        })
      }
      const handleClick = () => {
        emit('click')

        if (!$store.state.common.firstClickDatePickerKey) {
          $store.commit('setFirstClickDatePickerKey', props.pickerKey)
        }
        // auto open when click v-text-field
        isOpenDatePicker.value = true

        // if (props.readonly) {
        //   isOpenDatePicker.value = true
        // }
      }
      const clearTextField = e => {
        prevDateData.value = dateData.value
        dateFormat.value = 'YYYY/MM/DD'
        setTimeout(() => {
          if (document.activeElement === e.target) e.target.select()
        })
        emit('handleFocus', e.target)
      }

      const setMember = () => {
        currentMember.value = $store.state.common.memberInfo
        isShowGuide.value = currentMember.value.metadata?.is_show_calendar_guideline
      }
      const setTabindex = () => {
        const appendInnerElements = dateInputRef.value.$el?.querySelectorAll('.v-input__append-inner')
        const calendarElements = dateInputRef.value.$el?.querySelectorAll('.mdi-calendar')
        const cleadElements = dateInputRef.value.$el?.querySelectorAll('.mdi-close')
        appendInnerElements.forEach(element => {
          element.setAttribute('tabindex', '-1')
        })
        calendarElements.forEach(element => {
          element.setAttribute('tabindex', '-1')
        })
        cleadElements.forEach(element => {
          element.setAttribute('tabindex', '-1')
        })
      }

      const getErrorMessages = errors => {
        if (errors.length > 0) return errors
        return props.errorMessages
      }

      const hideGuideline = async e => {
        if (isConfirm.value) {
          loading.value = true
          e.stopPropagation()
          try {
            const {data} = await api.patch(`${endpoints.MASTER_MEMBER}update_guideline/${currentMember.value.id}`)
            $store.commit('setMemberInfo', data)
            setMember()
          } catch (e) {
            showError(e, $toast, 'ERROR')
            loading.value = false
          } finally {
            loading.value = false
            menu.value = false
          }
        } else {
          menu.value = false
        }
      }
      const focusDateInput = () => {
        dateInputRef.value.focus()
      }
      const resetToNull = () => {
        dateData.value = null
        valueInput.value = null
        // Reset data display to null
        $refs.dateInputRef.lazyValue = null
      }
      watch(
        () => [props.date, $store.state.common.memberInfo],
        ([newDate, newCurrentMember], [oldDate, oldCurrentMember]) => {
          dateData.value = newDate
          currentMember.value = newCurrentMember
          setMember()
        }
      )
      watch(
        () => props.date,
        () => {
          if (props.date) {
            valueInput.value = props.date
          } else if (props.allowNullValue) {
            valueInput.value = null
          }
        },
        {immediate: true}
      )
      onMounted(() => {
        if (props.autofocus) {
          nextTick(() => {
            if (dateInputRef.value) {
              focusDateInput()
            }
          })
        }
        if (props.outlined) {
          classForMenu.value = 'menu-guide-outlined'
        } else {
          classForMenu.value = 'menu-guide'
        }
        setMember()
        setTabindex()
      })
      onUnmounted(() => {
        $store.commit('setFirstClickDatePickerKey', null)
      })
      return {
        isOpenDatePicker,
        saveDate,
        dateData,
        dateDisplay,
        resetToNull,
        dateFormat,
        handleInputValue,
        valueInput,
        onInput,
        handleBlur,
        clearDate,
        componentKey,
        handleClick,
        clearTextField,
        dateInputRef,
        isShowGuide,
        menu,
        hideGuideline,
        currentMember,
        loading,
        menuActivator,
        classForMenu,
        isConfirm,
        isInputKeyMatched,
        getErrorMessages,
        focusDateInput,
        formatDateDay
      }
    }
  })

  export default CommonDatePicker
</script>
<style lang="sass">
  .input-date-outlined .v-input__append-inner:has(.v-input__icon--append)
    position: absolute
    left: 5px
    top: 0px
  .input-date-outlined input
    margin-left: 30px
  .input-date-outlined .v-label
    margin-left: 30px

  .input-date-outlined .v-label--active
    transform: translate(-30px, -18px) scale(0.75) !important


  .input-date .v-input__append-inner:has(.v-input__icon--append)
    position: absolute
    left: -5px
    top: 1px
  .input-date input
    margin-left: 30px
  .input-date .v-label
    margin-left: 30px
    top: 3px !important

  .input-date .v-label--active
    transform: translate(-30px, -16px) scale(0.75) !important
  .date-picker .label
    position: absolute
    top: 5px
    left: 75px
    color: white
  @keyframes shake
    59%
      left: 0
    60%, 80%
      left: 2px
    70%, 90%
      left: -2px
  .v-input--has-state.error--text .v-label
    -webkit-animation: shake 0.6s cubic-bezier(0.25, 0.8, 0.5, 1)
    animation: shake 0.6s cubic-bezier(0.25, 0.8, 0.5, 1)
  .icon-btn
    height: 22px !important
    width: 22px !important
    margin-left: 2px
  .icon-label
    font-size: 22px !important
    cursor: pointer
  .tooltip
    padding: 0px
    background: #4caf50 !important
    color: white !important
    font-weight: bold !important
    p
      margin-left: 16px !important
      margin-right: 16px !important
      .link
        //color: var(--v-primary-base)
        text-decoration: underline
        cursor: pointer
  .menu-guide
    padding: 10px 20px 20px 20px
    box-shadow: none
    .second
      padding-bottom: 8px !important
    .v-list
      border-radius: 4px
      box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12) !important
    .triangle
      position: relative
      top: -9px
      left: 30px
      width: 0
      height: 0
      border-left: 10px solid transparent
      border-right: 10px solid transparent
      border-bottom: 10px solid #4caf50
  .menu-guide-outlined
    margin-top: -8px
    padding: 10px 20px 20px 20px
    box-shadow: none
    .second
      padding-bottom: 4px !important
    .v-list
      border-radius: 4px
      box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12) !important
    .triangle
      position: relative
      top: -9px
      left: 30px
      width: 0
      height: 0
      border-left: 10px solid transparent
      border-right: 10px solid transparent
      border-bottom: 10px solid #4caf50
  .confirm-container
    display: flex
    margin-left: 14px !important
    .check-box
      .v-input--selection-controls__input
        .v-icon
          color: white
      .v-label
        color: white
        display: flex
        align-items: center
        padding-top : 2px !important
    .save-container
      margin-left: 64px
      padding-top: 2px
      .save
        background-color: white
        height: 24px !important
        padding-top: 2px !important
        .v-btn__content
          color: var(--v-primary-base) !important
</style>
