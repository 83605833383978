<template lang="pug">
.common-form-table.w-full
  slot(name='table_options')
  slot(name='table_custom_top_scroll')
  .wrapper-table-container(
    :class='{clipOverflow: isFixedHeader || isFixedHeaderSaleScreen || isClipOverFlow}',
    :id='"wrapper-table-container-" + domId')
    .table-container
      f-editable-table.items-table(
        ref='commonFrmTbl',
        :auto-focus-first-cell='autoFocusFirstCell',
        :column-sizes='columnSizes',
        :columns='columns',
        :context-actions='actions',
        :count-entire-total='countEntireTotal',
        :disabled='disabled',
        :domId='"table-container-" + domId',
        :is-allow-sorting='isAllowSorting',
        :is-move-right-on-enter='isMoveRightOnEnter',
        :is-show-context='isShowContext',
        :isFixedHeader='isFixedHeader',
        :isFixedHeaderSaleScreen='isFixedHeaderSaleScreen',
        :possibleStoreArrivalDate='possibleStoreArrivalDate',
        :rows='rows',
        :showFooter='showFooter',
        :showSummary='showSummary',
        :style='width ? `min-width: ${width}` : ""',
        :sub-columns='subColumns'
        @blur='event => onBlurCell(event)'
        @bodyclick='onBodyClick'
        @cell-enter='event => onEnterCell(event)'
        @checkbox-change='$emit("checkbox-change", $event)'
        @click-icon='$emit("click-icon", $event)'
        @click-icon-header='$emit("click-icon-header")'
        @cursor='updateCursor'
        @even-right-click='eventInRightClick'
        @input='onInput($event)'
        @on-searching-select-box='$emit("on-searching-select-box", $event), (currentSearchInput = $event.searchInput)'
        @on-select='$emit("on-select", $event)'
        @outsideclick='outsideclick'
        @reorder='onReorder'
        @update:columnSizes='changeColumnSizes')
        template(v-slot:header='{column}')
          slot(:name='"header-" + column.name')
            span(v-if='column.customHeaderName') {{column.customHeaderName}}
            span(v-else-if='column.isNullName') {{''}}
            // TODO: Refactor this using slot
            v-checkbox.ma-0.custom-checkbox(
              v-else-if='column.isDeliveryDate'
              v-model='column.isShowStoreDate'
              hide-details
              @click='$emit("showDelivery", {date: column.value, value: column.isShowStoreDate, isTotalColumn: false})')
              template(v-slot:label)
                span.white--text {{$t(`table_common.delivery_date`, {query: moment(column.name).format($t('common.month_date_day'))})}}
            span(v-else) {{$t(`table_common.${column.name}`)}}
            span.red--text {{column.required ? '*' : ''}}
          slot(:name='"header-" + column.name + "-icon"')
        template(v-slot:cell-last_bouquet='{row}')
          span(:style='{color: getColor(row.total_bouquet, row.last_bouquet)}') {{row.last_bouquet}}
        template(v-slot:cell-mix_details='{row}')
          v-btn(color='primary' icon small @click.stop='$emit("updateMix", row)')
            v-icon mdi-playlist-edit
        template(v-slot:cell-order_input='{row}')
          v-btn(:disabled='disabledInputOrder(row)' color='primary' icon small @click.stop='$emit("inputOrder", row)')
            v-icon mdi-playlist-edit
        template(v-slot:total)
          slot(name='total')
        template(v-slot:cell-input-dropdown-item-item='{dropdownItem}')
          v-row.px-0.mx-0.dropdown-item
            v-col.px-0.mx-0(cols='12')
              .pl-2 {{dropdownItem.name}}
        template(v-slot:cell-input-dropdown-item-variety='{dropdownItem}')
          v-row.px-0.mx-0.dropdown-item
            v-col.px-0.mx-0(cols='12')
              .pl-2 {{dropdownItem.name}}
        template(v-slot:cell-input-dropdown-item-color='{dropdownItem}')
          v-row.px-0.mx-0.dropdown-item
            v-col.px-0.mx-0(cols='12')
              .pl-2 {{dropdownItem.name}}
        template(v-slot:cell-input-dropdown-item-auction_lane='{dropdownItem}')
          v-row.px-0.mx-0.dropdown-item
            v-col.px-0.mx-0(cols='12')
              .pl-2 {{dropdownItem.name}}
        template(v-slot:cell-variety='{row, cell}')
          span(:class='{"variety--inactive": row.active == false}') {{cell}}
            v-badge.inactive__badge(
              v-if='row.active == false',
              :content='$t("common.inactive")'
              color='#CACACA'
              offset-x='2'
              offset-y='-2')
        template(v-slot:cell-opt='{row}')
          icon(:color='"darkgrey"', :item-val='row', :name='"opt"' icon-name='mdi-pencil' @on-click-icon='onClickIcon')
        template(v-slot:cell-remark='{row, cell}')
          .align-left(:class='xlRemark ? "three-dots--max-length" : "three-dots"') {{cell}}
        template(v-slot:no-data)
          slot(name='no-data')
  slot(name='pagination')
</template>

<script>
  import {defineComponent, getCurrentInstance, onMounted, toRefs, ref, onUnmounted, computed, watch} from 'vue'
  import {FEditableTable, ProductIcon, JHelp} from '@/components'
  import {addOneRow, addRows, deleteRows, copyRows, autoAddEmptyRow} from '@/utils'
  import {ProductType, ARRIVAL_TYPE} from '@/utils/constants'
  import {i18n} from '@/plugins'
  import {DEFAULT_ACTIONS} from './index'
  import _, {cloneDeep} from 'lodash'
  import moment from 'moment'

  const JCommonFormTable = defineComponent({
    components: {
      FEditableTable,
      ProductIcon,
      JHelp
    },
    props: {
      columns: {
        type: Array,
        default: () => []
      },
      // Above headers used in Summary page
      subColumns: {
        type: Array,
        default: () => []
      },
      rows: {
        type: Array,
        default: () => []
      },
      keyUpdate: {
        type: Number,
        default: 0
      },
      domId: {
        type: String,
        required: false,
        default: null
      },
      sizeGroups: {
        type: Array,
        required: false,
        default: () => []
      },
      unitGroups: {
        type: Array,
        required: false,
        default: () => []
      },
      boxTypeGroups: {
        type: Array,
        required: false,
        default: () => []
      },
      qualityGroups: {
        type: Array,
        required: false,
        default: () => []
      },
      colors: {
        type: Array,
        required: false,
        default: () => []
      },
      possibleStoreArrivalDate: {
        type: Object,
        required: false
      },
      width: {
        default: null
      },
      showShowHideDialog: {
        default: true
      },
      presetColumns: {
        default: () => []
      },
      presetColumn: {
        default: null
      },
      showFooter: {
        type: Boolean,
        required: false,
        default: false
      },
      actions: {
        type: Array,
        required: false,
        default: () => DEFAULT_ACTIONS
      },
      xlRemark: {
        type: Boolean,
        required: false,
        default: false
      },
      isAutoAddEmptyRow: {
        type: Boolean,
        required: false,
        default: true
      },
      isShowContext: {
        type: Boolean,
        required: false,
        default: true
      },
      isAllowSorting: {
        type: Boolean,
        default: true
      },
      showSummary: {
        type: Boolean,
        default: false
      },
      requiredFee: {
        type: Boolean,
        default: true
      },
      isFixedHeader: {
        type: Boolean,
        default: false
      },
      isClipOverFlow: {
        type: Boolean,
        default: false
      },
      isMoveRightOnEnter: {
        type: Boolean,
        default: true
      },
      isFixedHeaderSaleScreen: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      autoFocusFirstCell: {
        type: Boolean,
        default: false
      },
      columnSizes: {
        type: Object,
        default: () => {
          return {}
        }
      },
      defaultRowData: {
        type: Object,
        defaullt: () => {
          return null
        }
      },
      notCopyProps: {
        type: Array,
        default: () => []
      }
    },
    setup(props, {emit}) {
      const {$toast, $refs} = getCurrentInstance().proxy
      const {rows} = toRefs(props)
      const currentRowIndex = ref(null)
      const currentColumn = ref(null)
      const currentSearchInput = ref('')
      const menu = ref(false)
      const isShowSizeDialog = ref(false)
      const isShowBoxTypeDialog = ref(false)
      const isShowColorDialog = ref(false)
      const isShowItemDialog = ref(false)
      const isShowQualityDialog = ref(false)
      const isShowUnitDialog = ref(false)
      const isShowVarietyDialog = ref(false)
      const isShowPotSizeDialog = ref(false)
      const isShowArItemDialog = ref(false)
      const itemValue = ref({})

      const remarkValue = ''

      // const rows = ref(initRow(columns.value))
      const total = computed(() => {
        const output = {
          arrival_box_num: 0,
          stem: 0
        }
        const totalKeys = Object.keys(output)
        rows.value.forEach(row => {
          totalKeys.forEach(key => {
            output[key] +=
              !isNaN(Number(parseFloat(row[key]))) || Number(parseFloat(row[key])) === 0 ? parseFloat(row[key]) : 0
          })
        })
        return output
      })

      const getColor = (num, lastNum) => {
        if (num === null) return ''
        if (Number(num) > Number(lastNum)) return '#007167'
        if (Number(num) < Number(lastNum)) return '#FA0808'
        return ''
      }

      const eventInRightClick = event => {
        /*{
        "event": "handle_add_new_row",
        "actionPayload": {
          "row": {...},
          "rowIndex": 0,
          "column": {...},
          "columnIndex": 4,
          "rows": [
            {...},
            {...}
          ],
          "rowIndexes": [0],
          "columns": [{...}],
          "columnIndexes": [4]
        }
      }*/
        // prevent delete row event even if isShowContext is false
        if (!props.isShowContext) return

        const actionPayload = event.actionPayload
        const numberRowSelect = actionPayload.rows.length
        const firstRowIndex = actionPayload.rowIndexes[0]
        const lastRowIndex = actionPayload.rowIndexes[numberRowSelect - 1]

        if (event.event === 'handle_add_new_row_above') {
          rows.value = addRows(rows.value, numberRowSelect, firstRowIndex, true, props.defaultRowData)
        }
        if (event.event === 'handle_add_new_row_below') {
          rows.value = addRows(rows.value, numberRowSelect, lastRowIndex, false, props.defaultRowData)
        }
        if (event.event === 'handle_delete_rows') {
          // Do not delete disabled row, just emit event to handle if necessary
          const mapRows = cloneDeep(rows.value).map((r, idx) => {
            return {...r, idx: idx + 1}
          })
          const cannotDeleteRows = mapRows
            .slice(firstRowIndex, numberRowSelect + firstRowIndex)
            .filter(delR => delR?.disabledRow)
          const cannotDeleteRowIdxes = cannotDeleteRows.map(r => r.idx)
          if (cannotDeleteRowIdxes.length > 0) {
            emit('handle-delete-disabled-row', cannotDeleteRowIdxes)
            return
          }
          if (
            rows.value.slice(firstRowIndex, numberRowSelect + firstRowIndex).filter(delR => delR?.notEditable).length >
            0
          ) {
            $toast.error(i18n.t('common.can_not_delete_row'))
            return
          }
          const isDeleteAll = rows.value.length === numberRowSelect + firstRowIndex
          let isActuallyAddEmptyRow = false
          if (rows.value.length === numberRowSelect + firstRowIndex) {
            isActuallyAddEmptyRow = true
          }
          const [newRows, delRow] = deleteRows(rows.value, numberRowSelect, firstRowIndex)
          rows.value = newRows
          emit('on-delete-rows', {
            rows: rows.value,
            delRow,
            firstRowIndex,
            lastRowIndex,
            isDeleteAll
          })
          if (isActuallyAddEmptyRow) {
            emit('on-after-auto-add-empty-row', {
              rows: rows.value,
              emptyRowIndex: rows.value.length - 1
            })
          }
        }
        if (event.event === 'handle_copy_rows') {
          let copyDisabledFields = false
          if (event.actionPayload?.copyDisabledFields) copyDisabledFields = event.actionPayload?.copyDisabledFields

          rows.value = copyRows(
            rows.value,
            actionPayload.rows,
            lastRowIndex,
            props.columns,
            props.notCopyProps,
            copyDisabledFields
          )
          emit('on-copy-rows', {
            rows: rows.value,
            firstRowIndex,
            lastRowIndex,
            copyLength: actionPayload.rows.length
          })
        }
        if (event.event === 'handle_open_history') {
          emit('on-open-history', firstRowIndex)
        }
      }

      const onReorder = newRows => {
        emit('on-change', newRows.rows)
      }

      const countEntireTotal = () => {
        emit('count-entire-total')
      }

      const onBodyClick = curCursor => {
        emit('on-body-click', {
          row: rows.value[curCursor.rowIndex],
          column: props.columns[curCursor.columnIndex],
          rowIdx: curCursor.rowIndex
        })
      }
      const outsideclick = () => {
        emit('on-outside-click')
      }

      const closeDialog = dialog => {
        isShowSizeDialog.value = false
        isShowBoxTypeDialog.value = false
        isShowColorDialog.value = false
        isShowItemDialog.value = false
        isShowQualityDialog.value = false
        isShowUnitDialog.value = false
        isShowVarietyDialog.value = false
        isShowPotSizeDialog.value = false
        isShowArItemDialog.value = false
        emit('on-reload', dialog)
      }

      const onInput = dataInput => {
        if (dataInput.row) {
          // update new data into row exists
          const index = rows.value.indexOf(dataInput.row)
          rows.value[index][dataInput.column.name] = dataInput.value
          // Auto calculate stem, amount if inputting in box or quantity
          if (dataInput.column.name === 'box' || dataInput.column.name === 'quantity') {
            const box = rows.value[index].box
            const quantity = rows.value[index].quantity
            if (box && quantity) rows.value[index].stem = box * quantity

            const stem = rows.value[index].stem
            const price = rows.value[index].price

            if (stem && price) rows.value[index].amount = price * stem
          }
          // Auto calculate amount if inputting in price or quantity
          if (dataInput.column.name === 'price' || dataInput.column.name === 'stem') {
            const price = rows.value[index].price
            const stem = rows.value[index].stem

            if (stem && price) rows.value[index].amount = price * stem
          }
          if (props.isAutoAddEmptyRow && dataInput.key !== 'Delete' && dataInput.key !== 'Backspace') {
            let isActuallyAddEmptyRow = false
            if (rows.value.length - 1 === index) {
              isActuallyAddEmptyRow = true
            }
            autoAddEmptyRow(rows.value, index)
            if (isActuallyAddEmptyRow) {
              emit('on-after-auto-add-empty-row', {
                rows: rows.value,
                emptyRowIndex: index + 1
              })
            }
          }
        } else {
          // if row not exists => create new row with data is dataInput.value
          const dataRow = {}
          dataRow[dataInput.column.name] = dataInput.value
          rows.value = JSON.parse(JSON.stringify(addOneRow(rows.value, null, dataRow)))
        }
        emit('on-input', {rows: rows.value, dataInput: dataInput})
      }

      const index = 0
      const onEnterCell = event => {
        emit('on-enter-cell', {
          rowIdx: event.cursor.rowIndex,
          colIdx: event.cursor.columnIndex,
          row: rows.value[event.cursor.rowIndex],
          column: event.column
        })
      }
      const onClickIcon = event => {
        event.rowIndex = currentRowIndex.value
        emit('on-click-icon', event)
      }
      const updateCursor = event => {
        currentRowIndex.value = event.rowIndex
        emit('cursor', event)
      }
      const onBlurCell = data => {
        emit('on-blur-cell', {
          rowIdx: data.rowIndex,
          colIdx: data.columnIndex
        })
      }
      const isFixedHeader = ref(props.isFixedHeader)
      const isClipOverFlow = ref(props.isClipOverFlow)
      const changeColumnSizes = value => {
        emit('change-columnsizes', value)
      }
      const focusOnCell = cursor => {
        const tblDomId = `table-container-${props.domId}`
        const rowIdx = cursor.rowIndex
        const columnIdx = cursor.columnIndex
        if ($refs.commonFrmTbl?.cursor.columnIndex) {
          $refs.commonFrmTbl.cursor.columnIndex = columnIdx
          $refs.commonFrmTbl.cursor.rowIndex = rowIdx
        }

        const cell = document
          .getElementById(tblDomId)
          ?.querySelector(`td[data-column-index="${columnIdx}"][data-row-index="${rowIdx}"]`)
        cell?.click()
        const clickEvent = new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window
        })
        $refs.commonFrmTbl.$emit('cellclick', {
          rowIndex: rowIdx,
          columnIndex: columnIdx,
          $event: clickEvent
        })
      }

      // Fix the columns has .freeze class
      const watchFixedColumn = () => {
        const wrapperScrollbarTop = document.getElementById('wrapper-scrollbar-top-' + props.domId)
        const wrapperScrollbarBottom = document.getElementById('wrapper-scrollbar-bottom-' + props.domId)
        const tableContainer = document.getElementById('table-container-' + props.domId)
        const freezeElements = tableContainer.querySelectorAll('.freeze')
        function syncScroll(event) {
          const scrollLeftValue = event?.target.scrollLeft
          tableContainer.style.transform = `translateX(${-scrollLeftValue}px)`
          if (freezeElements) {
            freezeElements.forEach(element => {
              element.style.transform = `translateX(${scrollLeftValue}px)`
            })
          }
          if (event.target === wrapperScrollbarTop) {
            if (wrapperScrollbarBottom) wrapperScrollbarBottom.scrollLeft = scrollLeftValue
          } else if (event.target === wrapperScrollbarBottom) {
            if (wrapperScrollbarTop) wrapperScrollbarTop.scrollLeft = scrollLeftValue
          }
        }
        if (isFixedHeader.value || isClipOverFlow.value) {
          wrapperScrollbarTop?.addEventListener('scroll', syncScroll)
          wrapperScrollbarBottom?.addEventListener('scroll', syncScroll)
        }
      }

      // For customized scroll allowing sticky headers when scrolling
      const onSetScroll = () => {
        const wrapperScrollbarTop = document.getElementById('wrapper-scrollbar-top-' + props.domId)
        const wrapperScrollbarBottom = document.getElementById('wrapper-scrollbar-bottom-' + props.domId)
        const wrapperTableContainer = document.getElementById('wrapper-table-container-' + props.domId)

        const tableContainer = document.getElementById('table-container-' + props.domId)
        const freezeElements = tableContainer.querySelectorAll('.freeze')
        function syncScroll(event) {
          const scrollLeftValue = event?.target.scrollLeft
          tableContainer.style.transform = `translateX(${-scrollLeftValue}px)`
          if (freezeElements) {
            freezeElements.forEach(element => {
              element.style.transform = `translateX(${scrollLeftValue}px)`
            })
          }
          if (event.target === wrapperScrollbarTop) {
            if (wrapperScrollbarBottom) wrapperScrollbarBottom.scrollLeft = scrollLeftValue
          } else if (event.target === wrapperScrollbarBottom) {
            if (wrapperScrollbarTop) wrapperScrollbarTop.scrollLeft = scrollLeftValue
          }
        }

        // Take all element change attributes and style in mutationsList
        function updateScrollWhenTableContainerMove(mutationsList, observer) {
          mutationsList.forEach(mutation => {
            if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
              const element = mutation.target
              const style = window.getComputedStyle(element)
              const translateXValue =
                style.transform && style.transform !== 'none'
                  ? style.transform.split('(')[1].split(')')[0].split(',')[4]
                  : '0'
              if (wrapperScrollbarTop) wrapperScrollbarTop.scrollLeft = -translateXValue
              if (wrapperScrollbarBottom) wrapperScrollbarBottom.scrollLeft = -translateXValue
            }
          })
        }

        const observer = new MutationObserver(updateScrollWhenTableContainerMove)
        const config = {attributes: true, attributeFilter: ['style']}

        // Start observe tableContainer change
        observer.observe(tableContainer, config)

        if (isFixedHeader.value || isClipOverFlow.value) {
          wrapperScrollbarTop?.addEventListener('scroll', syncScroll)
          wrapperScrollbarBottom?.addEventListener('scroll', syncScroll)
        }

        let resizeObs = new ResizeObserver(entries => {
          for (let entry of entries) {
            const cr = entry.contentRect
            const scrollbarTopContainer = document.getElementById('scrollbar-top-container-' + props.domId)
            // padding left 4px, padding right 4px, need to plus 8px
            if (scrollbarTopContainer) scrollbarTopContainer.style.width = cr.width.toString() + 'px'
          }
        })
        let resizeObsBottom = new ResizeObserver(entries => {
          for (let entry of entries) {
            const cr = entry.contentRect
            const scrollbarBottomContainer = document.getElementById('scrollbar-bottom-container-' + props.domId)
            // padding left 4px, padding right 4px, need to plus 8px
            if (scrollbarBottomContainer) scrollbarBottomContainer.style.width = cr.width.toString() + 'px'
          }
        })
        if (tableContainer && wrapperTableContainer) {
          resizeObs.observe(tableContainer)
          resizeObsBottom.observe(tableContainer)
          onUnmounted(() => {
            resizeObs.unobserve(tableContainer)
            resizeObsBottom.unobserve(tableContainer)
          })
        }
        if (wrapperTableContainer && wrapperScrollbarTop) {
          wrapperScrollbarTop.onscroll = function () {
            wrapperTableContainer.scrollLeft = wrapperScrollbarTop.scrollLeft
          }
          wrapperTableContainer.onscroll = function () {
            wrapperScrollbarTop.scrollLeft = wrapperTableContainer.scrollLeft
          }
        }
      }
      onMounted(() => {
        onSetScroll()
      })

      const disabledInputOrder = row => {
        return (
          !row.arrival_type ||
          !row.arrival_box_num ||
          (row.arrival_type && row.arrival_type.includes(ARRIVAL_TYPE.CONSIGNMENT))
        )
      }
      watch(
        () => props.rows,
        () => {
          setTimeout(() => {
            watchFixedColumn()
          }, 1000)
        }
      )

      return {
        onInput,
        index,
        eventInRightClick,
        menu,
        onReorder,
        isShowItemDialog,
        isShowSizeDialog,
        isShowBoxTypeDialog,
        isShowColorDialog,
        isShowQualityDialog,
        isShowUnitDialog,
        isShowVarietyDialog,
        isShowPotSizeDialog,
        itemValue,
        currentColumn,
        currentSearchInput,
        onBodyClick,
        remarkValue,
        onEnterCell,
        onBlurCell,
        ProductType,
        total,
        closeDialog,
        isShowArItemDialog,
        changeColumnSizes,
        focusOnCell,
        disabledInputOrder,
        outsideclick,
        onClickIcon,
        updateCursor,
        countEntireTotal,
        getColor,
        moment
      }
    }
  })

  export default JCommonFormTable
</script>

<style scoped lang="sass">
    @import '@/style/css/common.sass'
    $sidebar-width: 100
    $padding-width: 16
    :deep(thead) tr
        th
          background-color: var(--v-primary-base) !important
          color: white !important
    .active
      color: rgb(104 159 56)
    .table-control-panel
      text-align: left
      margin-bottom: 5px
      padding: 0 0 0 5px
  .wrapper-table-container
    width: 100%
  //.wrapper-table-container
  //    -ms-overflow-style: none  /* Internet Explorer 10+ */
  //    scrollbar-width: none  /* Firefox */
  //.wrapper-table-container::-webkit-scrollbar
  //    display: none  /* Safari and Chrome */
  .three-dots
    text-overflow: ellipsis
    white-space: nowrap
    overflow: hidden
    width: 150px /* some width */
  .three-dots--max-length
    text-overflow: ellipsis
    white-space: nowrap
    overflow: hidden
    width: 350px /* some width */
  .align-left
    text-align: left
  .table-container
    width: 100%
  .common-form-table
    text-align: center
  .process-product
    text-align: left !important

  .f-editable-table.items-table
    margin-bottom: 8px
    background-color: #ffffff

    ::v-deep
      @mixin width($width)
        width: $width
        min-width: $width
          // Make table border radius
      //th:first-child
      //  border-top-left-radius: 4px
      //th:last-child
      //  border-top-right-radius: 4px
      //tr:nth-last-child(2) td:first-child
      //  border-bottom-left-radius: 4px
      //tr:nth-last-child(2) td:last-child
      //  border-bottom-right-radius: 4px

      // Make dummy row invisible
      tr:last-child td
        border: none
        opacity: 0
      // Adjust Header Stick to the top
      @media (min-width: #{$table-min-width + $sidebar-width + $padding-width}px)
        [class^="f-editable-table__header--"]
          position: sticky
          top: 0
          height: 48px
      // Adjust widths of columns

      td.f-editable-table__cell
        &--mix_details
          width: 30px
        &--article
          width: 50px
          min-width: 50px
        &--article_name
          width: 80px
          min-width: 80px
      th[class*="f-editable-table__header"]
        min-width: 70px
      th[class^="f-editable-table__header--sale_amount_"]
        width: 90px
      th[class^="f-editable-table__header--stem_amount_"]
        width: 90px
      th.f-editable-table__header
        &--arrival_type, &--grade, &--rank, &--bloom_num, &--packing, &--country, &--color, &--mps, &--reg_no
          @include width(85px)
        &--item, &--variety, &--stem, &--arrival_box_num, &--standing, &--bogie_no
          @include width(65px)
        &--override_description, &--mix
          @include width(100px)

        &--is_direct,&--published_box_num
          @include width(45px)
        &--order_input
          @include width(40px)
        &--rrn
          @include width(35px)

        &--product_type
          @include width(90px)

        &--sale_stem
          @include width(90px)

        &--sale_amount
          @include width(90px)

        &--assign_type
          @include width(90px)

        &--buyer_info
          @include width(90px)

        &--region, &--rgn_origin
          @include width(70px)

        &--images, &--count_flower, &--count_stem
          @include width(36px)

        &--pot_size
          @include width(72px)

        &--unit, &--box_type, &--quality, &--size
          @include width(72px)

        &--price, &--box, &--stem, &--remark, &--amount, &--tax_rate, &--average_price
          @include width(72px)
        &--mix_content
          @include width(36px)
        &--fee
          @include width(350px)
        &--rate
          @include width(150px)
        &--ar_item
          @include width(150px)
        &--ar_unit
          @include width(150px)
        &--processed_product
          @include width(250px)
        &--note
          @include width(200px)
        &--bunch_quantity, &--modular_code, &--item, &--variety, &--jan
          @include width(100px)
        &--quantity, &--sale_amount, &--stem_amount, &--remaining
          @include width(90px)
        &--flower_material, &--processing_company, &--processing_company_name, &--supplier, &--first_flower_material
          @include width(200px)
        &--total_bouquet, &--bouquet_per_box, &--product_area
          @include width(150px)
        &--last_bouquet, &--material_family_name, &--material_variety_name
          @include width(180px)
        &--row-number
           @include width(50px)
        &--supplier_name
           @include width(150px)
        &--grade_name, &--combination_code, &--box_type
          @include width(110px)
      td.f-editable-table__cell
        &--price, &--quantity, &--pieces_per_unit, &--box, &--stem, &--amount, &--average_price,
         &--standing, &--tax_rate, &--freight_amount, &--freight_bundle_num,
          &--freight_unit_cost, &--freight_tax, &--total_freight_amount
          text-align: right
          padding-right: 4px
        &--sold_out
          .v-input
            margin-top: 0px

          .v-input__control
            align-items: center

          .v-input__slot
            width: 25px

            .v-input--selection-controls__input
              margin-right: 0px

      .cell-input
        &--price, &--quantity, &--box, &--stem, &--amount, &--unit_cost, &--box_num, &--total_bouquet, &--monday, &--tuesday, &--wednesday, &--thursday, &--friday, &--saturday, &--sunday
          input
            text-align: right
            padding-right: 4px
        &--tax_rate input
          text-align: center
        &--icon, &--cutpot
          .v-input .v-input__append-inner
            display: none

    .v-text-field
      margin-top: 0
      padding-top: 0

    .inactive__badge ::v-deep span
      color: #6F6F6F

    ::v-deep
      .v-autocomplete.v-select.v-input--is-focused input
        min-width: 52px
  .dropdown-item
    cursor: pointer
  .justify-right
    position: absolute
    right: 0
  .v-menu__content
    min-width: 0 !important
  .check-box
    min-height: 35px
  .pointer
    cursor: pointer
  .select-member
    min-height: 15px
  .v-btn:not(.v-btn--round).v-size--default
      height: 40px !important
      min-width: 64px
      padding: 0 16px

  .clipOverflow
    overflow: clip

  ::v-deep .v-input--selection-controls__input .v-icon
    color: white
  ::v-deep .v-input--selection-controls__input .v-icon.v-icon--active
    background-color: white
    border-color: white
  .custom-checkbox
    display: inline-block
  .custom-checkbox :deep(.primary--text)
      color: white !important
</style>
