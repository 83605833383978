<template lang="pug">
.mt-0.mx-2.action-menu
  .search.d-flex.flex-fill.pr-10
    slot(name='pre-search')
    v-text-field.mb-1.input(
      v-model='searchInfoValue'
      v-next-focus,
      :autofocus='firstFocus',
      :label='searchText'
      color='primary'
      dense
      outlined
      prepend-inner-icon='mdi-magnify'
      @input='onInput()')
    slot(name='more-search')
  slot(name='button-action')
    div
      v-btn.mr-2.hidden-sm-and-up.yellow.button(v-if='isSortable' icon @click='onClickOpenSortDialog()')
        v-icon mdi-swap-vertical
      v-btn.mr-2.hidden-xs-only.button(v-if='isSortable' @click='onClickOpenSortDialog()')
        v-icon mdi-swap-vertical
        span {{$t('common.sort')}}

      v-btn.mr-2.hidden-sm-and-up.green-bg-btn.button(dark icon @click='onClickOpenAddDialog()')
        v-icon mdi-plus
      v-btn.mr-2.hidden-xs-only.button(color='primary' dark @click='onClickOpenAddDialog()')
        v-icon mdi-plus
        span {{$t('common.add')}}

      v-btn.mr-2.hidden-sm-and-up.green-bg-btn.button(
        v-if='enableSyncBtn',
        :loading='syncLoading'
        dark
        icon
        @click='onClickOpenAddDialog()')
        v-icon mdi-sync
      v-btn.mr-2.hidden-xs-only.button(
        v-if='enableSyncBtn',
        :loading='syncLoading'
        color='primary'
        dark
        @click='onClickSync()')
        v-icon mdi-sync
        span {{$t('common.sync')}}

  .switch-wrapper(v-if='switchItem')
    span.prepend-label {{$t('common.inactive')}}
    v-switch.hidden-xs-only.switch(v-model='isActiveValue' color='primary' @change='onChangeActive')
    span.append-label {{$t('common.active')}}
</template>

<script>
  import {defineComponent, getCurrentInstance, ref, watch} from 'vue'
  import {api} from '@/plugins/index.ts'
  import {endpoints} from '@/utils'

  export default defineComponent({
    props: {
      searchInfo: {
        type: String,
        required: true
      },
      searchText: {
        type: String,
        required: true
      },
      enableSyncBtn: {
        type: Boolean,
        default: false
      },
      isActive: {
        type: Boolean,
        default: true,
        required: false
      },
      firstFocus: {
        type: Boolean,
        default: false
      },
      isSortable: {
        type: Boolean,
        default: true
      },
      switchItem: {
        type: Boolean,
        default: false
      }
    },
    setup(props, {emit}) {
      const instance = getCurrentInstance()
      const {$toast, $root} = instance.proxy
      const searchInfoValue = ref(props.searchInfo || '')
      const syncLoading = ref(false)
      const isActiveValue = ref(props.isActive)
      const onInput = () => {
        emit('inputting', searchInfoValue.value)
      }

      // Click to open ADD dialog
      const onClickOpenAddDialog = () => {
        emit('open-add-dialog')
      }
      const onClickOpenSortDialog = item => {
        emit('open-sort-dialog', item)
      }
      const onClickSync = async () => {
        syncLoading.value = true
        try {
          const {data} = await api.post(`${endpoints.MASTER_SETTING}sync-to-catalog`)
          syncLoading.value = false
          $toast.success($root.$t('message.sync_success'))
          console.log(data)
        } catch (e) {
          $toast.error($root.$t('message.sync_error'))
          console.log(e)
          syncLoading.value = false
        }
      }

      // handle change active
      const onChangeActive = () => {
        emit('handle-change-active', isActiveValue.value)
      }
      watch(
        () => props.searchInfo,
        () => {
          searchInfoValue.value = props.searchInfo
        }
      )
      watch(
        () => props.isActive,
        () => {
          isActiveValue.value = props.isActive
        }
      )

      return {
        onInput,
        searchInfoValue,
        onClickOpenAddDialog,
        onClickOpenSortDialog,
        onClickSync,
        syncLoading,
        isActiveValue,
        onChangeActive
      }
    }
  })
</script>

<style lang="sass">
  @import '@/style/css/common.sass'
  .switch-wrapper
    display: flex
    align-items: center
    height : 40px

  .prepend-label
    margin-right: 8px
  .append-label
    margin-left: 3px

  .button
    height: 40px !important

  .action-menu
    max-width: 1200px
    padding-top: 30px
    display: flex

    .divider
      background-color: var(--v-primary-base)

    .input
      .v-input__slot
        background: #ffffff
        min-height: 40px !important

      .v-input__slot:hover
        background: #ffffff !important

    .categories-list
      position: absolute
      left: 8px
      right: 8px
      z-index: 4
      overflow: auto
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5)

      .category-title
        padding: 4px

        .v-icon
          color: var(--v-primary-base)
          padding: 2px !important

  .v-application .mx-2
    margin: 0 auto !important

  .green-bg-btn
    background-color: var(--v-primary-base)
    border-color: var(--v-primary-base)
</style>
