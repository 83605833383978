<template lang="pug">
div
  j-master-dialog(
    ref='master_dialog',
    :background-color='"#007167"',
    :color-text='"white"',
    :height='"65vh"',
    :hide-fields='hideFields',
    :is-add='isAdd',
    :is-on-mobile='isOnMobile',
    :isAutofocus='false',
    :label='isAdd ? $t("variety.add") : $t("variety.edit")',
    :label-name='$t("common.name")',
    :loading='loading',
    :master-data='varietyData',
    :show='show'
    type='variety'
    @on-close='closeDialog'
    @on-create='createItem'
    @on-update='updateItem')
    template(#top)
      validation-provider(v-slot='{errors}', :name='$t("common.item")' rules='required')
        v-autocomplete#material_family_id(
          v-model='varietyData.material_family_id'
          v-next-focus,
          :error-messages='errors',
          :filter='(item, queryText, itemText) => filterBySearchString(item, queryText)',
          :items='itemList'
          autofocus
          clearable
          item-text='name'
          item-value='id')
          template(v-slot:label)
            span {{$t('common.item')}}
            span.red--text *
          template(v-slot:no-data)
            span.pl-5.grey--text {{$t('message.no_data')}}
    template(#middle)
      v-text-field#eng_name(v-model='varietyData.eng_name' v-next-focus, :label='$t("common.eng_name")')
      v-autocomplete#supplier(
        v-model='varietyData.supplier_id'
        v-next-focus,
        :filter='(item, queryText, itemText) => filterBySearchString(item, queryText)',
        :items='suppliersList',
        :label='$t("material_variety.supplier")'
        clearable
        item-text='name'
        item-value='id')
        template(v-slot:no-data)
          span.pl-5.grey--text {{$t('message.no_data')}}
      validation-observer(ref='observer' v-slot='{invalid}')
        validation-provider(v-slot='{errors}', :name='$t("material_variety.assorted_type")' rules='required')
          v-autocomplete#assort_type(
            v-model='varietyData.assort_type'
            v-next-focus,
            :error-messages='errors',
            :filter='(item, queryText, itemText) => filterBySearchString(item, queryText)',
            :items='assortedType'
            hide-details
            item-text='name'
            item-value='name')
            template(v-slot:no-data)
              span.pl-5.grey--text {{$t('message.no_data')}}
            template(v-slot:label)
              span {{$t('material_variety.assorted_type')}}
              span.red--text *

  j-confirm-dialog(
    :cancel-text='$t("common.cancel")',
    :messages='[$t("message.confirm_used_in_assignment")]',
    :ok-text='$t("common.save")',
    :showDialog='showConfirmUsedDialog'
    @on-cancel='showConfirmUsedDialog = false'
    @on-close='showConfirmUsedDialog = false'
    @on-ok='updateItem(false), (showConfirmUsedDialog = false)')
</template>
<script>
  import {defineComponent, toRefs, ref, getCurrentInstance, watch, onMounted} from 'vue'
  import {ValidationProvider, ValidationObserver} from 'vee-validate'
  import JMasterDialog from '../JMasterDialog/index'
  import JConfirmDialog from '../JConfirmDialog/index'
  import {api} from '@/plugins'
  import {showError, filterBySearchString} from '@/utils'
  import {assortedType} from '@/utils/constants'

  const JItemDialog = defineComponent({
    components: {
      JConfirmDialog,
      JMasterDialog,
      ValidationProvider,
      ValidationObserver
    },
    props: {
      show: {type: Boolean, required: true},
      item: {type: Object, required: false, default: null},
      isAdd: {type: Boolean, required: false, default: true},
      curItemId: {required: false},
      prevInput: {type: String, required: false, default: ''},
      isOnMobile: {type: Boolean, required: false, default: false}
    },
    setup(props, {emit}) {
      const {$toast, $root, $refs} = getCurrentInstance().proxy
      const {item} = toRefs(props)
      const loading = ref(false)
      const suppliersList = ref([])
      const hideFields = ['code', 'full_name', 'keyword']
      const MATERIAL_VARIETY_ENDPOINT = `/api/material-varieties`

      const varietyData = ref({})
      const isSuccessAll = ref(true)
      const showConfirmUsedDialog = ref(false)

      const closeDialog = () => {
        loading.value = false
        emit('on-close')
        resetValidate()
      }

      const validateElement = async () => {
        const validate = await $refs.observer.validate()
        if (!validate) {
          $toast.error($root.$t('message.check_data_type'))
          return false
        }
        return true
      }

      const resetValidate = async () => {
        const validateName = await $refs.observer
        validateName.reset()
      }

      const convertData = () => {
        Object.keys(varietyData.value).forEach(key => {
          if (varietyData.value[key] === '') varietyData.value[key] = null
        })
      }

      const createItem = async () => {
        loading.value = true
        convertData()
        if (!(await validateElement())) {
          loading.value = false
          return
        }
        try {
          const {data} = await api.post(MATERIAL_VARIETY_ENDPOINT, varietyData.value)
          if (isSuccessAll.value) $toast.success($root.$t('message.create_successful'))
          emit('on-reload', data)
          closeDialog()
        } catch (e) {
          // if api return duplicate --> show dialog confirm and allow save in db
          showError(e, $toast, $root.$t('message.update_failed'))
        } finally {
          loading.value = false
          await resetValidate()
        }
      }

      const updateItem = async () => {
        loading.value = true
        try {
          const {supplier_name, ...bodySave} = varietyData.value
          await api.patch(MATERIAL_VARIETY_ENDPOINT + `/${varietyData.value.id}`, bodySave)
          if (isSuccessAll.value) $toast.success($root.$t('message.create_successful'))
          emit('on-reload')
          closeDialog()
        } catch (e) {
          // if api return duplicate --> show dialog confirm and allow save in db
          showError(e, $toast, $root.$t('message.update_failed'))
        } finally {
          loading.value = false
        }
      }
      const confirmUpdate = async () => {
        try {
          await api.post(`${MATERIAL_VARIETY_ENDPOINT}check?id=${varietyData.value.id}`)
          await updateItem()
        } catch (e) {
          showConfirmUsedDialog.value = true
        }
      }

      const init = () => {
        if (props.show) {
          if (props.isAdd) {
            const varietyInit = {
              material_family_id: parseInt(props.curItemId),
              eng_name: '',
              supplier_id: null,
              assort_type: assortedType[0].name
            }
            varietyData.value = JSON.parse(JSON.stringify(varietyInit))
          } else {
            varietyData.value = JSON.parse(JSON.stringify(item.value))
          }
        }
      }

      // 品目
      const itemList = ref([])
      const getItem = async () => {
        try {
          const {data} = await api.get(`/api/material-families`)
          itemList.value = data
        } catch (e) {
          showError(e, $toast, $root.$t('message.get_data_failed'))
        }
      }

      const getSuppliers = async () => {
        try {
          const {data} = await api.get(`/api/suppliers`)
          suppliersList.value = data
        } catch (e) {
          showError(e, $toast, $root.$t('message.get_data_failed'))
        }
      }

      onMounted(() => {
        Promise.all([getItem(), getSuppliers()])
      })
      watch(
        () => props.show,
        () => {
          init()
        }
      )

      return {
        closeDialog,
        loading,
        varietyData,
        updateItem,
        createItem,
        confirmUpdate,
        showConfirmUsedDialog,
        itemList,
        hideFields,
        filterBySearchString,
        suppliersList,
        assortedType
      }
    }
  })

  export default JItemDialog
</script>

<style lang="sass" scoped>
  .content
    padding: 10px
  .auto-button
    padding-bottom: 4px
  .required
    content: '99'
    color: red
  .list-item
    max-height: 550px
    overflow-y: auto
  .v-list-item
    padding: 0 !important
    min-height: 35px !important

  .item
    display: flex
    align-items: center

  .item .code
    display: inline-block
    width: 100px
    text-align: left
    padding-left: 20px
  .item .name
    display: inline-block
    width: calc(100% - 100px)
    text-align: left
    padding-right: 20px
  .icon-list
    font-size: 20px
    display: inline-block
    justify-content: center
    align-items: center


  .color-btn
    background-color: #bdbdbd

  .switch-wrapper
    display: flex
    align-items: center
    margin-top: 8px
</style>
